export const environment = {
  production: true,
  local: false,
  url: 'https://www.roundpier.com',
  endpoint: 'https://www.roundpier.com',
  prefix: '/api/',
  socketEndpoint: 'https://dm.roundpier.com',
  socketPort: '443',
  routePrefix: '/',
  groupChatSocketHost: 'https://gc.roundpier.com',
  groupChatSocketPort: 443,
  fbqID: '1124504484375704',
  domain: 'roundpier.com',
  tokenName: 'token',
  collegesURL: 'https://colleges.roundpier.com',
  sentryDsn:
    'https://e9a944056ff24fbcbf437b124161ee1f@o974232.ingest.sentry.io/5965176',
  hj: {
    id: '2956210',
    version: 6
  },
  auth: {
    appleClientID: 'com.roundpier',
    googleClientID:
      '712347469417-vd0dc4mfvajamk7uhuskn9jl04klep99.apps.googleusercontent.com',
    redirectURL: 'https://www.roundpier.com'
  },
  amplitude: {
    apiKey: '893e8c10466d3d2cae7f381e3e9df580'
  },
  appleStoreURL:
    'https://itunes.apple.com/us/app/roundpier/id1400305303?ls=1&mt=8',
  googlePlayURL:
    'https://play.google.com/store/apps/details?id=com.roundpier.roundpier'
};
